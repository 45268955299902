import React, {useEffect, useRef} from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import moment from 'moment';
import HiddenInput from 'components/Form/HiddenInput';
import Input from 'components/Form/Input';
import {DATE_FORMAT_ISO} from 'constants/uiConstants';

export default function ServiceRecordForm({
    dirty, isValid, isSubmitting, handleChange, handleSubmit, setFieldValue,
    isEditable, isLoading, getMotionOperationTime
}) {
    const isMounted = useRef(false);
    const isDisabled = !isEditable || isLoading;

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    const handleServiceDateChange = (e) => {
        handleChange(e);
        getMotionOperationTime(
            e.target.value,
            value => isMounted.current && setFieldValue('operating_time', value, false)
        );
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Input label="Date of last service" type="date" name="service_date" max={moment().format(DATE_FORMAT_ISO)} onChange={handleServiceDateChange} required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Cumulative operating hours at last service" type="number" min="0" step="1" name="operating_time" required disabled={isDisabled} />
            </Row>
            <HiddenInput name="id" />
            {isEditable && <Button type="submit" className="btn-form-submit" disabled={!isValid || isSubmitting || isDisabled}>Save</Button>}
        </Form>
    )
};
