export const alertTypes = {
    'warning': 'Warning', 'reminder': 'Reminder'
};
export const capacityUnits = {
    lbs: 'Lbs',
    tons: 'Tons',
    mton: 'MTon',
    kg: 'Kg',
    other: 'Other',
};
export const configurationStatus = {
    0: 'Draft', 1: 'Initiated to publish', 2: 'Published'
};
export const craneCommunicationTypes = {
    0: 'PLC', 1: 'MODBUSRTU'
};
export const dataExportStatuses = {
    1: 'Pending', 2: 'No data', 3: 'Created', 4: 'Failed'
};
export const dataExportTypes = {
    'motion': 'Motion data', 'odometer': 'Odometer data'
};
export const eventActions = {
    1: 'Send data to cloud', 2: 'Send email', 3: 'Send SMS'
};
export const loadCellModes = {
    1: '2 points', 2: '3 points'
};
export const maintenanceItemStatuses = {
    1: 'Pending', 2: 'Done'
};
export const motionTypes = {
    1: 'Hoist', 2: 'Miscellaneous', 3: 'Rotate', 4: 'Traverse'
};
export const reportTemplateStatuses = {
    1: 'Draft', 2: 'Published'
};
export const reportTemplateParameters = {
    'operation_time': 'Operation time', 'calendar_time_in_operation': 'Calendar time in operation'
};
export const userRoles = {
    1: 'System Admin', 2: 'Customer Admin', 3: 'Site Admin', 4: 'Location Admin', 5: 'Crane System Admin',
    101: 'System User', 102: 'Customer User', 103: 'Site User', 104: 'Location User', 105: 'Crane System User',
};
export const vfdParities = {
    0: 'No parity', 1: 'Even parity', 2: 'Odd parity'
};
export const vfdClasses = {
    0: 'VG+S4', 1: 'VG+S3', 2: 'G+S4', 3: 'G+S3', 4: 'G+M'
};
