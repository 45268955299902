import React, {useEffect, useRef, useState} from 'react';
import {useAppContext} from 'contexts/AppContext';
import NotificationDetails from './NotificationDetails';
import NotificationsList from './NotificationsList';

export default function Notifications(props) {
    const isMounted = useRef(false);
    const {setMenuItem, setPageTitle} = useAppContext();
    const [showDetails, setShowDetails] = useState(false);
    const [notification, setNotification] = useState({});

    useEffect(() => {
        isMounted.current = true;
        setMenuItem('/app/notifications');
        setPageTitle('Maintenance alerts');
        return () => isMounted.current = false;
    }, []);

    const handleDetailsClick = (notification) => {
        setNotification(notification);
        setShowDetails(true);
    };

    const handleCloseClick = () => setShowDetails(false);

    if (showDetails) return <NotificationDetails notification={notification} onCloseClick={handleCloseClick} />;
    return <NotificationsList onDetailsClick={handleDetailsClick} />
}
