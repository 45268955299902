import React, {useEffect, useRef, useState} from 'react';
import {Button, Spinner, Table} from 'react-bootstrap';
import moment from 'moment';
import {downloadFaultData, getFaultsList} from 'api/FaultsApi';
import ModalDialog from 'components/Dialogs/ModalDialog';
import {DATETIME_FORMAT} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';
import FaultsData from './FaultsData';

const FileSaver = require('file-saver');

export default function FaultsList({crane, filters, isLoading, setIsLoading}) {
    const isMounted= useRef(false);
    const {handleApiError} = useAppContext();
    const [showDialog, setShowDialog] = useState(false);
    const [faultsList, setFaultsList] = useState([]);
    const [faultObject, setFaultObject] = useState({});

    useEffect(() => {
        isMounted.current = true;
        loadFaultsList();
        return () => isMounted.current = false;
    }, [crane, filters]);

    const loadFaultsList = () => {
        setIsLoading(true);
        const onSuccess = response => isMounted.current && setFaultsList(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getFaultsList(crane.id, filters, onSuccess, handleApiError, onDone);
    };

    const downloadData = date_to => {
        const payload = {
            date_to: date_to,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        const fileName = `fault-data-${Date.now()}.csv`;
        const onSuccess = response => FileSaver.saveAs(response.data, fileName);
        downloadFaultData(crane.id, payload, onSuccess, handleApiError);
    };

    const showFaultCodes = e => {
        const td = e.target.parentElement;
        const ul = td.getElementsByTagName('ul');
        ul[0].classList.toggle('hidden');
    };

    const showData = fault => {
        setFaultObject(fault);
        setShowDialog(true);
    };

    const handleModalClosed = () => setShowDialog(false);

    const dialogContent = <FaultsData crane={crane} faultObject={faultObject} />;

    let tableContent;
    if (isLoading) tableContent = <tr><td colSpan="5" className="text-center"><Spinner animation="border" /></td></tr>;
    else if (faultsList.length === 0) tableContent = <tr><td colSpan="5">No faults found</td></tr>;
    else {
        tableContent = (
            <>
                {faultsList.map((item, idx) => {
                    const createdAt = moment(item.created_at).utc(true).format(DATETIME_FORMAT);
                    const faultsCount = Object.keys(item.faults).length;
                    let faultCodes = '';
                    let faultCodesDetails = '';
                    if (faultsCount === 1) {
                        faultCodes = `${Object.keys(item.faults)[0]}: ${Object.values(item.faults)[0]}`;
                    }
                    else {
                        faultCodes = <span className="text-primary text-link" onClick={showFaultCodes}>{faultsCount} faults</span>;
                        faultCodesDetails = Object.entries(item.faults).map(([faultCode, faultText]) => <li key={faultCode}>{faultCode}: {faultText}</li>);
                    }
                    return (
                        <tr key={idx}>
                            <td className="text-center">{createdAt}</td>
                            <td className="text-center">{item.motion.name}</td>
                            <td className="text-center fault-codes">{faultCodes}<ul className="hidden">{faultCodesDetails}</ul></td>
                            <td className="text-center">
                                <span className="text-primary text-link" onClick={() => showData(item)}>Key VFD Parameters</span>
                            </td>
                            <td className="text-center">
                                <span className="text-primary text-link" onClick={() => downloadData(item.created_at)}>Download</span>
                            </td>
                        </tr>
                    );
                })}
            </>
        );
    }

    return (
        <>
            <ModalDialog title="Key VFD parameters" body={dialogContent} show={showDialog} onHide={handleModalClosed} size="lg">
                <Button variant="primary" onClick={handleModalClosed}>Close</Button>
            </ModalDialog>
            <Table striped bordered hover id="faults-list">
                <thead className="thead-centered">
                    <tr>
                        <th>Date</th>
                        <th>Motion</th>
                        <th>Details</th>
                        <th>Key VFD Parameters</th>
                        <th>Crane Operation Data Export</th>
                    </tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </Table>
        </>
    )
}
