import React from 'react';
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import {AppContextProvider} from 'contexts/AppContext';
import AdminLayout from 'layouts/AdminLayout';
import AppLayout from 'layouts/AppLayout';
import AuthLayout from 'layouts/AuthLayout';
import {isAuthenticated} from 'utils/Auth';

export default function SmartCraneApp() {
    const redirectTo = isAuthenticated() ? '/app/dashboard' : '/auth/login';
    return (
        <AppContextProvider>
            <HashRouter>
                <Switch>
                    <Route path='/auth' component={AuthLayout} />
                    <Route path='/admin' component={AdminLayout} />
                    <Route path='/app' component={AppLayout} />
                    <Redirect to={redirectTo} />
                </Switch>
            </HashRouter>
        </AppContextProvider>
    );
}
