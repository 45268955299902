import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Pagination, Spinner, Table} from 'react-bootstrap';
import moment from 'moment';
import {getNotificationsList} from 'api/NotificationsApi';
import {alertTypes} from 'constants/dataConstants';
import {DATETIME_FORMAT} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';
import {isSystemRole} from 'utils/Auth';

export default function NotificationsList({onDetailsClick}) {
    const isMounted = useRef(false);
    const {setPageTitle, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);
    const [recordsCount, setRecordsCount] = useState(0);
    const [filters, setFilters] = useState({limit: 15, offset: 0});
    const [page, setPage] = useState(1);
    const showPrev = page >= 2;
    const showNext = page * filters.limit < recordsCount;
    const showCustomer = isSystemRole();

    useEffect(() => {
        isMounted.current = true;
        setPageTitle('Maintenance alerts');
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        loadNotificationsList();
        return () => {};
    }, [page]);

    const loadNotificationsList = () => {
        setIsLoading(true);
        const onSuccess = response => {
            setNotifications(response.data.notifications_list);
            setRecordsCount(response.data.count);
        };
        const onDone = () => isMounted.current && setIsLoading(false);
        getNotificationsList(filters, onSuccess, handleApiError, onDone);
    };

    const handlePageClick = (direction) => {
        setFilters(prevFilters => {
            let page = prevFilters.offset / prevFilters.limit + 1;
            page += direction === 'forward' ? 1 : -1;
            setPage(page);
            const offset = (page - 1) * prevFilters.limit;
            return {...prevFilters, offset: offset};
        });
    };

    let tableContent;
    if (isLoading) {
        tableContent = <tr><td colSpan={showCustomer ? 7 : 6} className="text-center"><Spinner animation="border" /></td></tr>;
    }
    else if (notifications.length) {
        tableContent = notifications.map(notification => {
            const alertTime = moment.utc(notification.created_date).local().format(DATETIME_FORMAT);
            const craneLink = {pathname: '/app/cranes-details', state: {crane: notification.crane}};
            return (
                <tr key={notification.id}>
                    <td className="text-center">{alertTime}</td>
                    <td className="text-center">{alertTypes[notification.alert_type]}</td>
                    <td style={{fontWeight: 'bold'}}><Link to={craneLink}>{notification.crane.name}</Link></td>
                    {showCustomer && <td>{notification.customer_details.customer_name}</td>}
                    <td>{notification.report.name}</td>
                    <td>
                        <span className="text-primary text-link" onClick={() => onDetailsClick(notification)}>
                            {notification.maintenance_item.name}
                        </span>
                    </td>
                    <td>
                        <span className="text-primary text-link" onClick={() => onDetailsClick(notification)}>
                            {notification.content}
                        </span>
                    </td>
                </tr>
            );
        });
    }
    else {
        tableContent = <tr><td colSpan={showCustomer ? 7 : 6}>No maintenance alerts found</td></tr>;
    }

    return (
        <>
            <Pagination className="pagination-sm ac-pagination" style={{marginTop: 0, marginBottom: '15px'}}>
                <Pagination.Prev className="ac-page-link" onClick={() => handlePageClick('backward')} disabled={!showPrev} />
                <Pagination.Item className="ac-page-item" active>{page}</Pagination.Item>
                <Pagination.Next className="ac-page-link" onClick={() => handlePageClick('forward')} disabled={!showNext} />
            </Pagination>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th className="text-center">Alert date</th>
                        <th className="text-center">Type</th>
                        <th>Crane</th>
                        {showCustomer && <th>Customer</th>}
                        <th>Report</th>
                        <th>Maintenance item</th>
                        <th>Content</th>
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </Table>
        </>
    );
}
