import React from 'react';
import {Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {arrayToObject, convertSecondsToTimeString} from 'utils/Utils';

const CHART_MARGINS = {top: 15, right: 30, left: 20, bottom: 5};
const COLORS = {'run_time': '#ff883e', 'load_cycle_count': '#7D3C98', 'motor_speed_avg': '#003082'};

export default function OdometerChart({odometerData, odometerParameters, selectedParameters}) {

    const formatYAxis = (parameter, value) => {
        if (parameter === 'run_time') return convertSecondsToTimeString(value);
        return value;
    };

    const formatTooltip = (value, name, props) => {
        if (name === 'Operating time') value = convertSecondsToTimeString(value);
        return [value, name];
    };

    if (selectedParameters.length >= 1 && selectedParameters.length <= 2) {
        const isSecondParam = selectedParameters.length === 2;
        const isFirstParamLine = selectedParameters[0] === 'motor_speed_avg';
        const isSecondParamLine = isSecondParam && selectedParameters[1] === 'motor_speed_avg';
        const isSecondParamBar = isSecondParam && !isSecondParamLine;
        const parameterNames = arrayToObject(odometerParameters, 'name', 'display');
        const parameter_1 = selectedParameters[0];
        const parameter_2 = isSecondParam ? selectedParameters[1] : null;
        const color_1 = COLORS[parameter_1];
        const color_2 = isSecondParam ? COLORS[parameter_2] : null;
        const name_1 = parameterNames[parameter_1];
        const name_2 = isSecondParam ? parameterNames[parameter_2] : null;
        return (
            <ResponsiveContainer width="100%" height={350}>
                <ComposedChart data={odometerData.list} margin={CHART_MARGINS}>
                    <XAxis height={50} dataKey="k" label={{value: 'Load (% of crane rated capacity)', position: 'insideBottom'}} />
                    <YAxis
                      yAxisId={1} tick={{fill: color_1}} tickFormatter={value => formatYAxis(parameter_1, value)}
                      label={{value: name_1, angle: -90, position: 'left', offset: parameter_1 === 'run_time' ? 15: 5}}
                    />
                    {isSecondParam &&
                        <YAxis
                          yAxisId={2} tick={{fill: color_2}} tickFormatter={value => formatYAxis(parameter_2, value)}
                          label={{value: name_2, angle: -270, position: 'right', offset: parameter_2 === 'run_time' ? 20: 5}}
                          orientation="right"
                        />}
                    <Tooltip formatter={formatTooltip} />
                    <Legend verticalAlign="top" height={80} />
                    <CartesianGrid stroke="#f5f5f5" />
                    {isFirstParamLine ?
                        <Line yAxisId={1} type="monotone" name={name_1} dataKey={parameter_1} stroke={color_1} /> :
                        <Bar yAxisId={1} dataKey={parameter_1} name={name_1} barSize={40} fill={color_1} />}
                    {isSecondParamLine && <Line yAxisId={2} type="monotone" name={name_2} dataKey={parameter_2} stroke={color_2} />}
                    {isSecondParamBar && <Bar yAxisId={2} dataKey={parameter_2} name={name_2} barSize={40} fill={color_2} />}
                </ComposedChart>
            </ResponsiveContainer>
        );
    }
}
