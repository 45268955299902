import React, {useEffect, useRef, useState} from 'react';
import {getEventParams} from 'api/EventsApi';
import {arrayToObject} from 'utils/Utils';
import EventDetails from './EventDetails';
import EventsList from './EventsList';

export default function EventsConfig(props) {
    const isMounted = useRef(false);
    const [showDetails, setShowDetails] = useState(false);
    const [event, setEvent] = useState({});
    const [eventParams, setEventParams] = useState({});

    useEffect(() => {
        isMounted.current = true;
        getEventParams(handleGetEventParams);
        return () => isMounted.current = false;
    }, []);

    const handleGetEventParams = (response) => {
        if (isMounted.current) setEventParams(arrayToObject(response.data, 'name', 'display'));
    };

    const handleAddButtonClick = () => {
        setEvent({});
        setShowDetails(true);
    };

    const handleDetailsClick = (event) => {
        setEvent(event);
        setShowDetails(true);
    };

    const handleCloseButtonClick = () => {
        setShowDetails(false);
    };

    if (showDetails) return <EventDetails {...props} event={event} eventParams={eventParams} onCloseButtonClick={handleCloseButtonClick} />;

    return <EventsList {...props} eventParams={eventParams} onDetailsClick={handleDetailsClick} onAddButtonClick={handleAddButtonClick} />;
}
