import React, {useEffect, useRef, useState} from 'react';
import {Button, Spinner, Table} from 'react-bootstrap';
import {deleteServiceRecord, getServiceRecordsList} from 'api/MaintenanceApi';
import moment from 'moment';
import ModalDialog from 'components/Dialogs/ModalDialog';
import {DATE_FORMAT} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';

let serviceRecordObject;

export default function ServiceRecordsList({customReport, maintenanceItem}) {
    const isMounted = useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogBody, setDeleteDialogBody] = useState(<></>);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [serviceRecords, setServiceRecords] = useState([]);

    useEffect(() => {
        isMounted.current = true;
        loadServiceRecordsList();
        return () => isMounted.current = false;
    }, []);

    const loadServiceRecordsList = () => {
        setIsLoading(true);
        const onSuccess = response => setServiceRecords(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getServiceRecordsList(
            customReport.crane_reference, customReport.id, maintenanceItem.id,
            onSuccess, handleApiError, onDone
        );
    };

    const handleDeleteClick = (serviceRecord) => {
        serviceRecordObject = serviceRecord;
        setDeleteDialogBody(
            <p>Do you really want to delete the service record from "<b>{moment(serviceRecord.service_date).format(DATE_FORMAT)}</b>"?</p>
        );
        setShowDeleteDialog(true);
    };

    const handleModalClosed = () => {
        setShowDeleteDialog(false);
    };

    const handleModalConfirmed = () => {
        setIsLoading(true);
        const onDone = () => isMounted.current && setIsLoading(false);
        deleteServiceRecord(
            customReport.crane_reference, customReport.id,
            maintenanceItem.id, serviceRecordObject.id,
            handleSuccessResponse, handleApiError, onDone
        );
        handleModalClosed();
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Service record has been successfully deleted');
        if (isMounted.current) loadServiceRecordsList();
    };

    let tableContent;
    if (isLoading) tableContent = <tr><td colSpan="4" className="text-center"><Spinner animation="border" /></td></tr>;
    else if (serviceRecords.length === 0) tableContent = <tr><td colSpan="4">No service records found</td></tr>;
    else {
        const serviceRecordsCount = serviceRecords.length;
        tableContent = serviceRecords.map((serviceRecord, idx) => (
            <tr key={serviceRecord.id}>
                <td className="text-center">{moment(serviceRecord.service_date).format(DATE_FORMAT)}</td>
                <td className="text-center">{serviceRecord.service_date_difference}</td>
                <td className="text-center">{serviceRecord.operating_time}</td>
                <td className="text-center">{serviceRecord.operating_time_difference}</td>
                <td className="text-center">
                    {idx > 0 && idx + 1 === serviceRecordsCount &&
                        <span className="text-primary text-link" onClick={() => handleDeleteClick(serviceRecord)}>Delete</span>}
                </td>
            </tr>
        ));
    }

    return (
        <>
            <ModalDialog title="Deleting service record" body={deleteDialogBody} show={showDeleteDialog} onHide={handleModalClosed} size="lg">
                <Button variant="secondary" onClick={handleModalClosed}>No</Button>
                <Button variant="danger" onClick={handleModalConfirmed}>Yes</Button>
            </ModalDialog>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th className="text-center">Date of service</th>
                        <th className="text-center">Days since previous service</th>
                        <th className="text-center">Operating hours at service</th>
                        <th className="text-center">Operating hours since previous service</th>
                        <th />
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </Table>
        </>
    );
}
