import React, {useEffect, useRef} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import {Card, Col, Row} from 'react-bootstrap';
import PerfectScrollbar from 'perfect-scrollbar';
import Footer from 'components/Footer/Footer';
import MainNavbar from 'components/Navbars/MainNavbar';
import Sidebar from 'components/Sidebar/Sidebar';
import {useAppContext} from 'contexts/AppContext';
import adminRoutes from 'routes/adminRoutes';
import {getUserRole} from 'utils/Auth';
import 'react-toastify/dist/ReactToastify.css';

const backgroundColor = 'white';
const activeColor = 'primary';
let ps;

export default function AppLayout(props) {
    const isMounted = useRef(false);
    const mainPanel = useRef(null);
    const {pageTitle} = useAppContext();
    const location = useLocation();
    const userRole = getUserRole();

    useEffect(() => {
        isMounted.current = true;
        if (navigator.platform.indexOf('Win') > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            document.body.classList.toggle('perfect-scrollbar-on');
        }
        return () => {
            isMounted.current = false;
            if (navigator.platform.indexOf('Win') > -1) {
                ps.destroy();
                document.body.classList.toggle('perfect-scrollbar-on');
            }
        };
    }, []);

    useEffect(() => {
        mainPanel.current.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [location]);

    return (
        <div className="wrapper">
            <Sidebar routes={adminRoutes} bgColor={backgroundColor} activeColor={activeColor} {...props} />
            <div className="main-panel" ref={mainPanel}>
                <MainNavbar pageTitle={pageTitle} />
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Switch>
                                {adminRoutes.map(route => {
                                    if (!route.roles.includes(userRole)) return null;
                                    return (
                                        <Route
                                          key={route.path.slice(1)}
                                          path={route.path}
                                          render={props => {
                                              const component = <route.component {...props} />;
                                              if (route.name === 'Dashboard') return component;
                                              return (
                                                  <Card border="secondary">
                                                      <Card.Body>{component}</Card.Body>
                                                  </Card>
                                              );
                                          }}
                                        />
                                    );
                                })}
                            </Switch>
                        </Col>
                    </Row>
                </div>
                <Footer fluid />
            </div>
        </div>
    )
};
