import ApiClient from './ApiClient';

const apiClient = ApiClient('/maintenance');

export const getMaintenanceReportsList = (craneId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getMaintenanceReport = (craneId, reportId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/${reportId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const createServiceRecord = (craneId, customReportId, maintenanceItemId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/service/records/${craneId}/${customReportId}/${maintenanceItemId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const getServiceRecordsList = (craneId, customReportId, maintenanceItemId, onSuccess, onError, onDone) => {
    apiClient.get(`/service/records/${craneId}/${customReportId}/${maintenanceItemId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const deleteServiceRecord = (craneId, customReportId, maintenanceItemId, serviceRecordId, onSuccess, onError, onDone) => {
    apiClient.delete(`/service/records/${craneId}/${customReportId}/${maintenanceItemId}/${serviceRecordId}/`).then(onSuccess).catch(onError).finally(onDone);
};
