import React, {useEffect, useRef, useState} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import PerfectScrollbar from 'perfect-scrollbar';
import Footer from 'components/Footer/Footer';
import MainNavbar from 'components/Navbars/MainNavbar';
import Sidebar from 'components/Sidebar/Sidebar';
import adminRoutes from 'routes/adminRoutes';
import {getUserRole} from 'utils/Auth';
import {getBrand} from 'utils/Ui';

const backgroundColor = 'white';
const activeColor = 'primary';
let ps;

export default function AdminLayout(props) {
    const isMounted = useRef(false);
    const mainPanel = useRef(null);
    const [pageTitle, setPageTitle] = useState('Dashboard');
    const location = useLocation();
    const userRole = getUserRole();

    useEffect(() => {
        isMounted.current = true;
        if (navigator.platform.indexOf('Win') > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            document.body.classList.toggle('perfect-scrollbar-on');
        }
        return () => {
            isMounted.current = false;
            if (navigator.platform.indexOf('Win') > -1) {
                ps.destroy();
                document.body.classList.toggle('perfect-scrollbar-on');
            }
        };
    }, []);

    useEffect(() => {
        mainPanel.current.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        setPageTitle(getBrand(adminRoutes));
    }, [location]);

    return (
        <div className="wrapper">
            <Sidebar routes={adminRoutes} bgColor={backgroundColor} activeColor={activeColor} {...props} />
            <div className="main-panel" ref={mainPanel}>
                <MainNavbar pageTitle={pageTitle} />
                <Switch>
                    {adminRoutes.map(route => {
                        if (!route.roles.includes(userRole)) return null;
                        return (
                            <Route
                              key={route.path.slice(1)}
                              path={route.path}
                              render={props => {
                                  return <route.component setPageTitle={setPageTitle} {...props} />;
                              }}
                            />
                        );
                    })}
                </Switch>
                <Footer fluid />
            </div>
        </div>
    );
};
